import * as React from "react"

const SIcon = (props) => (
  <svg
    width={120}
    height={100}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path fill="#fff" d="M0 0h120v100H0z" />
      <ellipse
        cx={60}
        cy={50}
        rx={55}
        ry={50}
        fill="#F47D83"
        fillOpacity={0.5}
      />
      <circle cx={50} cy={50} r={50} fill="#F47D83" />
      <path
        d="M40.544 71.928c-2.136 0-4.043-.396-5.723-1.188-1.68-.768-3-1.836-3.96-3.204s-1.44-2.928-1.44-4.68c0-.48.035-1.104.108-1.872.072-.792.168-1.668.288-2.628.12-.96.252-1.944.395-2.952.169-1.032.325-2.028.468-2.988l5.437.72c-.24 1.608-.456 3.072-.648 4.392-.169 1.296-.3 2.4-.397 3.312-.096.888-.144 1.548-.144 1.98 0 .888.217 1.656.649 2.304.431.672 1.068 1.188 1.907 1.548.84.36 1.86.54 3.06.54 1.273 0 2.28-.192 3.025-.576.743-.36 1.271-.852 1.584-1.476.312-.6.468-1.284.468-2.052V48.6h5.508v14.508c0 1.776-.456 3.324-1.368 4.644-.913 1.32-2.172 2.34-3.78 3.06-1.584.744-3.397 1.116-5.437 1.116ZM51.057 63l.072-4.716h2.556c.743 0 1.32-.108 1.727-.324.409-.24.684-.708.828-1.404.168-.72.252-1.8.252-3.24V46.8h5.509v6.516c0 2.256-.3 4.104-.9 5.544-.6 1.416-1.512 2.46-2.736 3.132C57.14 62.664 55.58 63 53.684 63h-2.629Zm12.456 0a8.92 8.92 0 0 1-3.133-.576 10.178 10.178 0 0 1-2.844-1.764l3.349-3.456c.431.36.864.636 1.296.828.431.168.876.252 1.332.252h6.875l-3.023 2.664v-7.812c0-.36-.048-.924-.144-1.692a34.6 34.6 0 0 0-.36-2.664 73.767 73.767 0 0 0-.505-3.06l5.437-.828c.167.864.323 1.836.467 2.916.168 1.08.313 2.1.432 3.06.12.96.18 1.716.18 2.268V63h-9.36Z"
        fill="#fff"
      />
    </g>
  </svg>
)

export default SIcon
