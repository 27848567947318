import * as React from "react"

const Twitter = (props) => (
  <svg
    width={35}
    height={35}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.55 28h-7.1V12.945h7.1V28Zm-5.681-1.084h4.262V14.029H6.87v12.887ZM9 12.12c-2.206 0-4-1.372-4-3.06C5 7.374 6.794 6 9 6c2.205 0 4 1.373 4 3.06 0 1.688-1.794 3.06-4 3.06Zm0-5.036c-1.424 0-2.582.887-2.582 1.977 0 1.089 1.158 1.975 2.582 1.975s2.582-.886 2.582-1.975c0-1.09-1.158-1.977-2.582-1.977ZM31 28h-6.676v-7.622c0-2.226-.316-3.285-2.11-3.285-1.85 0-2.538.858-2.538 3.164V28H13V12.36h6.46v1.029C20.479 12.618 21.98 12 23.923 12 30.16 12 31 15.78 31 19.477V28Zm-5.342-1.126h4.008v-7.398c0-3.92-.961-6.35-5.744-6.35-2.353 0-3.876 1.101-4.466 2.044l-.187.3h-1.143v-1.984h-3.792v13.388h4.008v-6.617c0-1.174 0-4.29 3.872-4.29 3.444 0 3.444 2.87 3.444 4.411v6.496Z"
      fill="#000"
    />
  </svg>
)

export default Twitter