import * as React from "react"

const WIcon = (props) => (
  <svg
    width={120}
    height={100}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path  d="M0 0h120v100H0z" />
      <ellipse
        cx={60}
        cy={50}
        rx={55}
        ry={50}
        fill="#FDD456"
        fillOpacity={0.5}
      />
      <circle cx={50} cy={50} r={50} fill="#FDD456" />
      <path
        d="M49.705 72.288c-.912 0-1.86-.072-2.844-.216a32.36 32.36 0 0 1-3.096-.54l.828-4.392a91.12 91.12 0 0 0 2.988.324c.84.072 1.548.108 2.124.108 1.632 0 2.808-.288 3.528-.864.72-.576 1.08-1.452 1.08-2.628V48.816l1.116.792h-3.996c-.816 0-1.5.108-2.052.324-.552.192-.972.612-1.26 1.26-.264.624-.396 1.596-.396 2.916 0 1.224.12 2.136.36 2.736.24.6.636.996 1.188 1.188.552.168 1.272.252 2.16.252h2.88V63h-2.88c-2.016 0-3.72-.324-5.112-.972-1.368-.648-2.4-1.632-3.096-2.952-.672-1.32-1.008-2.976-1.008-4.968 0-2.016.336-3.708 1.008-5.076a6.902 6.902 0 0 1 3.096-3.096c1.392-.696 3.096-1.044 5.112-1.044h8.388V64.08c0 1.68-.42 3.132-1.26 4.356-.84 1.248-2.016 2.196-3.528 2.844-1.512.672-3.288 1.008-5.328 1.008Z"
        fill="#fff"
      />
    </g>

  </svg>
)

export default WIcon
